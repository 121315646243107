.top-sec-full {
  width: 100%;
  display: flex;
  flex-flow: column;
}

.collectionName {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.button-container {
  display: flex;
  justify-content: space-between;
  gap: 200px;
  white-space: nowrap;
}

.status-block {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  height: 100px;

  .success {
    color: green;
    font-size: 32px;
  }

  .loading {
    color: blue;
    font-size: 32px;
  }
}

.field-info {
  display: flex;
  align-items: center;
  margin-left: 15px;

  .fields {
    font-size: 20px;
    margin: 5px 0;
  }

  .icons {
    margin-left: 10px;
    margin-bottom: -5px;
  }
}

.progress-bar {
  margin: 20px 0;
}

.progress-wrapper {
  margin-top: 20px;
  width: 700px;
  max-width: 80%;
  align-self: center;
}

.results-component {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  border: 3px solid #0e172c;
  padding: 25px 20px;

  .block-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .three-col-block {
    display: flex;
    width: 80%;
    justify-content: space-between;
    border: 0.5px solid #ccc;
    padding: 15px;

    .column {
      padding: 10px;
      text-align: center;

      .result-done-icon {
        margin-top: 15px;
      }

      .result-circle {
        margin-top: 15px;

        > div {
          justify-content: center;
        }
      }

      .sm-title {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 5px;
      }
    }
  }
}
