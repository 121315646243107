.site-box {
  width: 20.7vw;
  height: 40vh;
  flex-shrink: 0;
  font-size: 12px;
  border-radius: 2.14vh;
  background: #FFF;
  // box-shadow: inset 0 0 0 1px rgba(31, 31, 31, 0.17),
  //   0 1px 2px rgba(7, 6, 6, 0.699);
  // border-radius: 8px;
  // border: 3px solid #0e172c;
  overflow: hidden;
  // background-color: white;

  // padding: 1.4vh 0.75vw;


  
  a {
    text-decoration: none;  
    color: black;
    font-weight: bold;
  }

  &:hover {
    cursor: pointer;
  }

  .site-img-block {
    margin: 1.7vh 0.8vw;
    height: 26vh;
    flex-shrink: 0;
    border-radius: 1.60vh;
    // border-bottom: 2px solid #0e172c;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .site-info {
    // box-shadow: inset 0 0 0 1px rgba(31, 31, 31, 0.17),
    // 0 1px 2px rgba(31, 31, 31, 0.05);
    padding-top: 1vh;
    padding-left: 1.5vw;
    display: flex;
    color: var(--text-color-1, #27262E);
    font-feature-settings: 'salt' on;
    font-family: Poppins;
    font-size: 2.8vh;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    // background-color: #d9d4e7;
    // align-items: center;
    justify-content: space-between;
  }
}

.mr-lr {
  margin: 20px 15px 0;
}