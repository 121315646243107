.link-block {
  text-decoration: none;
  color: inherit;
}

.icon {
  width: 80px;
}

.desc {
  font-size: 16px;
}
