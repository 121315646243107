.white-box-profile{
    border-radius: 1.606vh;
    background: #FFF;
    height: 61.57vh;
    padding: 4.016vh 1.95vw;

}
.profile-heading{
    color: #1F1F1F;
    font-family: Poppins;
    font-size: 1.5625vw;
    font-style: normal;
    font-weight: 700;
    line-height: 5.6224vh;
}
.profile-container{
    display: flex;
    align-items: center;
    gap: 32.552vw;
}
.actions-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4.016vh;
    width: 100%;
}
.edit-mode-on{
    margin-top: 5.35vh;
}
.edit-mode-off{
    margin-top: 8.032vh;
}
.delete-btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.edit-button{
    display: flex;
    padding: 1.606vh 0.781vw;
    align-items: center;
    gap:  0.39vw;
    border-radius: 4.016vh;
    background-color: #C5C6D0;
    color: #A2A0A0;
    font-family: Poppins;
    font-size: 0.911vw;
    font-style: normal;
    font-weight: 400;
    line-height: 1.874vh;
    cursor: pointer;
}
.box-input{
    border: 1px solid lightgray;
    border-radius: 0.669vh;
    padding: 1.338vh 0.651vw;
    width: 28.645vw;
    color: #2D334A;
    font-family: Poppins;
    font-size: 1.041vw;
    font-style: normal;
    font-weight: 400;
    opacity: 0.6;
    display: flex;
    padding: 1.874vh 1.5625vw;
    align-items: center;
    gap: 0.651vw;
    line-height: 2.677vh;
}
.form-profile{
    margin-top: 5.89vh;
}
.details-container-name{
    align-items: center;
    display: flex;
    gap: 13.02vw;
    margin-bottom: 3.212vh;
}
.details-container-email{
    align-items: center;
    display: flex;
    gap: 16.01vw;
    margin-bottom: 5.354vh;
}
.name-container{
    color: #2D334A;
    font-family: Poppins;
    font-size: 1.041vw;
    font-style: normal;
    font-weight: 400;
    line-height: 2.677vh;
    opacity: 0.6;
}
.email-container{
    color: #2D334A;
    font-family: Poppins;
    font-size: 1.041vw;
    font-style: normal;
    font-weight: 400;
    line-height: 2.677vh;
    opacity: 0.6;
}
.payment-details{
    align-items: center;
    display: flex;
    gap: 9.765vw;
}
.payment-container{
    color: #2D334A;
    font-family: Poppins;
    font-size: 1.041vw;
    font-style: normal;
    font-weight: 400;
    line-height: 2.67vh;
    opacity: 0.6;
}
.billing{
    color: #2D334A;
    font-family: Poppins;
    font-size: 1.041vw;
    font-style: normal;
    font-weight: 400;
    line-height: 2.677vh;
    cursor: pointer;
    text-decoration-line: underline;
    opacity: 0.6;
}
.error-box{
    border-color: red;
    outline: none;
  }
  .error-text {
    color: red;
  }