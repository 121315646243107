@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&family=Montserrat:wght@400;600&family=Outfit:wght@400;500&display=swap");
.section-main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  max-width: 95em;
  width: 100%;
  font-family: "Inter" !important;
  font-size: 1vw;
  margin: auto;
  margin-top: 1.875em;
  padding-left: 2.4305555556em;
  padding-right: 2.4305555556em;
}
.main-block-new {
  width: 100%;
  // padding: 0 6em 0 6.3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.2222222222em;
}
.top-btn-wrapper {
  display: flex;
  width: 100%;
  padding: 2.2222222222em 2.7777777778em;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.7361111111em;
  border-radius: 1.7361111111em;
  background: #fff;
  -webkit-border-radius: 1.7361111111em;
  -moz-border-radius: 1.7361111111em;
  -ms-border-radius: 1.7361111111em;
  -o-border-radius: 1.7361111111em;
}
.sub-title-32 {
  color: #000;
  font-size: 2.2222222222em;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700;
  line-height: 1.28125; /* 41.6px */
}
.back-btn-wrapper {
  display: flex;
  align-items: center;
  gap: 0.8333333333em;
}
a {
  text-decoration: none;
}
.sub-head-20 {
  color: #000;
  font-size: 1.3888888889em;
  font-style: normal;
  font-weight: 700;
  line-height: 1; /* 26px */
  font-family: "Inter" !important;
}
.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #ffd803;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  align-self: center;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.top-bottom-block-new {
  display: flex;
  align-items: flex-start;
}

.flex-container {
  display: flex;
  align-items: stretch;
  width: 100%;
  gap: 2.7777777778em;
}
.ml-10 {
  margin-left: 0.4em;
}
.left-panel {
  border-radius: 1.7361111111em;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 20.8333333333em;
  padding: 3.125em 0;
  gap: 3.3333333333em;
  -webkit-border-radius: 1.7361111111em;
  -moz-border-radius: 1.7361111111em;
  -ms-border-radius: 1.7361111111em;
  -o-border-radius: 1.7361111111em;
}
.width-287 {
  width: 100%;
  margin: 0 auto;
  max-width: 19.9305555556em;
  max-height: 40em;
  overflow: auto;
}
.width-351 {
  width: 100%;
  margin-left: auto;
  max-width: 24.375em;
  max-height: 30em;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.scroll-bar {
  margin-right: 0.5em;
  padding-right: 0.5em;
}
/*width*/
.scroll-bar::-webkit-scrollbar {
  width: 0.5555555556em;
}

/*thumb*/
.scroll-bar::-webkit-scrollbar-thumb {
  background: #7a7a7a;
  border-radius: 0.4em;
  -webkit-border-radius: 0.4em;
  -moz-border-radius: 0.4em;
  -ms-border-radius: 0.4em;
  -o-border-radius: 0.4em;
}
.top-sec-new {
  border-radius: 1.7361111111em;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 26.6666666667em;
  padding: 3.125em 0;
  gap: 3.3333333333em;
}
.field-sec {
  border-radius: 1.7361111111em;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 38.4722222222em;
  padding: 3.125em 2.0833333333em;
  gap: 3.3333333333em;
}

.top-sec .item-details {
  margin-bottom: 1em;
}

.flex-row-head-wrapper {
  display: flex;
  align-items: center;
  padding: 0 1.5277777778em 0 1.5277777778em;
}

.text-24 {
  color: #000;
  font-size: 1.6666666667em;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
  font-family: "Inter" !important;
}

.check-box-btn {
  width: 100%;
  height: 100%;
  max-width: 1.59em;
  max-height: 1.59em;
}

.is-selected {
  background-color: #ffd80387;
  width: 100%;
  padding-left: 0.5em;
  border-radius: 0.4em;
}

.is-selected .sub-head-20 {
  font-weight: 700;
}

.collection-item + .item-details {
  transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
}
.weight-400 {
  font-weight: 400;
}
.collection-item,
.item-details {
  display: flex;
  padding: 1.0416666667em 1.5277777778em;
  flex-direction: row;
  align-items: flex-start;
  cursor: pointer;
  gap: 1em;
}

.item-details {
  align-items: flex-start;
}
.collection-item .PrivateSwitchBase-root-1 {
  padding: 0;
  margin-right: 0em;
}
.item-details .PrivateSwitchBase-root-1 {
  padding: 0;
  margin-right: 0em;
}
.collection-item > .MuiFormControlLabel-root,
.item-details > .MuiFormControlLabel-root {
  margin-right: 0px;
}
.collection-item>label>span{
  padding: 0;
}
.field-details-wrapper {
  width: 100%;
  max-width: 37.2222222222em;
  display: flex;
  flex-direction: column;
  gap: 3.3333333333em;
  max-height: 34em;
  overflow: auto;
  padding-left: 1em;
}

.min-height-30 {
  min-height: 30em;
}

.min-height-45 {
  min-height: 45em;
}

.field-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.3888888889em;
  align-self: stretch;
}

.field-details .PrivateSwitchBase-root-1 {
  padding: 0;
  margin-right: 0em;
}
.field-details .PrivateSwitchBase-root-1 {
  padding: 0;
  margin-right: 0em;
}
.field-details > .MuiFormControlLabel-root {
  margin-right: 0px;
}
.item-details > .MuiFormControlLabel-root span {
  padding: 0;
}
.two-row-flex {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.field-type {
  width: 100%;
}

.image-icon {
  display: flex;
  align-items: center;
  gap: 0.2777777778em;
}

.icon-16 {
  width: 1.1111111111em;
  height: 1.1111111111em;
}
.image-with-name {
  display: flex;
  align-items: center;
  gap: 3.4722222222em;
  width: 100%;
  max-width: 25.2777777778em;
  border-radius: 0.5555555556em;
  border: 1px solid #d9d9d9;
  -webkit-border-radius: 0.5555555556em;
  -moz-border-radius: 0.5555555556em;
  -ms-border-radius: 0.5555555556em;
  -o-border-radius: 0.5555555556em;
}

.field-image {
  max-width: 8.3333333333em;
  height: 8.3333333333em;
  object-fit: contain;
}

.field-flex {
  display: flex;
  flex-direction: column;
  gap: 1.8055555556em;
}

.image-name {
  color: #000;
  font-family: "Inter" !important;
  font-size: 1.1111111111em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.rich-text-field {
  display: flex;
  padding: 1.0416666667em;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.6944444444em;
  align-self: stretch;
  border-radius: 0.5555555556em;
  border: 1px solid #d9d9d9;
  -webkit-border-radius: 0.5555555556em;
  -moz-border-radius: 0.5555555556em;
  -ms-border-radius: 0.5555555556em;
  -o-border-radius: 0.5555555556em;
}

.optimised-by {
  padding: 0.3333333333em 0.6666666667em;
  background: #272343;
  color: #fff;
  font-family: "Inter" !important;
  font-size: 1.0416666667em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  border-radius: 1.9333333333em;
  -webkit-border-radius: 1.9333333333em;
  -moz-border-radius: 1.9333333333em;
  -ms-border-radius: 1.9333333333em;
  -o-border-radius: 1.9333333333em;
  -webkit-border-radius: 1.9333333333em;
}

.result-container {
  display: flex;
  align-items: center;
  gap: 2.7777777778em;
  width: 100%;
}

.result-left-block {
  display: flex;
  flex-direction: column;
  padding: 1.3888888889em 0.6944444444em;
  align-items: flex-start;
  gap: 1.73em;
  width: 100%;
  max-width: 33.1944444444em;
  border-radius: 1.7361111111em;
  background: #fff;
  max-height: 42em;
  overflow: auto;
  -webkit-border-radius: 1.7361111111em;
  -moz-border-radius: 1.7361111111em;
  -ms-border-radius: 1.7361111111em;
  -o-border-radius: 1.7361111111em;
}

.btn-wrapper {
  align-self: center;
}

.btn-wrapper > p {
  margin-left: 1em;
}

.btn-wrapper .back-button {
  margin-bottom: 0.5em;
}

.full-width-for-result {
  max-width: none;
  width: 100%;
  border-right: 0;
}

.result-wrapper {
  display: flex;
  width: 100%;
  // max-width: 31.9444444444em;
  padding: 1.7361111111em;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.3888888889em;
  border-radius: 1.3888889em;
  border: 1px solid #d9d9d9;
  background: #fff;
  -webkit-border-radius: 1.3888889em;
  -moz-border-radius: 1.3888889em;
  -ms-border-radius: 1.3888889em;
  -o-border-radius: 1.3888889em;
}

.p-btm-45 {
  padding-bottom: 3.125em;
}
.text-18 {
  color: #000;
  font-family: "Inter" !important;
  font-size: 1.25em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.625;
}
.width-100 {
  width: 100%;
}

.field-info-wrapper {
  display: flex;
  align-items: center;
}

.content-info-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.4em;
  width: 100%;
}
.tags-wrapper {
  display: flex;
  gap: 0.5em;
  flex-direction: column;
  align-items: flex-start;
}
.tag {
  color: #272343;
  font-family: "Inter" !important;
  font-size: 0.6em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.5em;
  text-align: center;
  background-color: #ffd803;
  border-radius: 0.1em;
  -webkit-border-radius: 0.5em;
  margin: 0 0.4em 0.2em 0;
}
.result-field-img {
  width: 100%;
  height: 100%;
  // max-width: 9.0277777778em;
  // max-height: 8.3333333333em;
}

.status-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.0416666667em;
  width: 100%;
  max-width: 11.1111111111em;
}

.result-state-wrapper {
  display: flex;
  align-items: center;
  gap: 0.8333333333em;
}
.text-13 {
  color: #000;
  font-family: "Inter" !important;
  font-size: 0.9027777778em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.status-tag {
  padding: 0.4615384615em 0.7692307692em;
  gap: 0.7692307692em;
  text-align: center;
  border-radius: 3.4615384615em;
  background: #272343;
  color: #fff;
  text-transform: capitalize;
  -webkit-border-radius: 3.4615384615em;
  -moz-border-radius: 3.4615384615em;
  -ms-border-radius: 3.4615384615em;
  -o-border-radius: 3.4615384615em;
}

.result-progress {
  width: 1.6666666667em;
  height: 1.6666666667em;
}
.optimized-info-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.0416666667em;
}

.result-field-multi-img {
  display: flex;
  width: 20em;
  flex-wrap: wrap;
}

.result-field-multi-img > img {
  width: 3em;
}

.ml-2 {
  margin-left: 0.2em;
}
.text-14 {
  color: #000;
  font-family: Inter;
  font-size: 0.9722222222em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.result-right-block {
  border-radius: 1.7361111111em;
  background: #fff;
  display: flex;
  padding: 4em;
  width: 100%;
  max-width: 57.7083333333em;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 1.7361111111em;
  -moz-border-radius: 1.7361111111em;
  -ms-border-radius: 1.7361111111em;
  -o-border-radius: 1.7361111111em;
}

.result-right-wrapper {
  max-width: 33.2638888889em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.6388888889em;
}

.tag-28 {
  padding: 0.4861111111em 1.3888888889em;
  border-radius: 3.2638888889em;
  background: #272343;
  color: #fff;
  font-family: "Inter" !important;
  font-size: 1.9444444444em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
  -webkit-border-radius: 3.2638888889em;
  -moz-border-radius: 3.2638888889em;
  -ms-border-radius: 3.2638888889em;
  -o-border-radius: 3.2638888889em;
}

.stats-wrapper{
  display: flex;
  width: 100%;
max-width: 32.8472222222em;
justify-content: center;
align-items: flex-start;
gap: 2.4305555556em;
}
.stats-block{
  display: flex;
  padding: 0.4861111111em 0.625em;
  flex-direction: column;
  align-items: center;
  gap: 0.3472222222em;
  flex: 1 0 0;
  border-radius: 0.3472222222em;
  border: 0.79px solid #D9D9D9;
  background: #FFF;
  -webkit-border-radius: 0.3472222222em;
  -moz-border-radius: 0.3472222222em;
  -ms-border-radius: 0.3472222222em;
  -o-border-radius: 0.3472222222em;
}

.font-28{
  color: #000;
font-family: "Inter" !important;
font-size: 1.9444444444em;
font-style: normal;
font-weight: 700;
line-height: 1.2; /* 36.4px */
}

.font-15{
  color: #000;
font-family: "Inter" !important;
font-size: 1.0416666667em;
font-style: normal;
font-weight: 700;
line-height: 1.2; /* 19.5px */
}
// @media only screen and (min-width: 1441px) {
//   .main-block-new {
//     padding: 0 6em 0 8.5em;
//   }
//   .left-panel {
//     max-width: 31.33em;
//   }
//   .top-sec-new {
//     max-width: 31.33em;
//   }
//   .field-sec {
//     max-width: 31.33em;
//   }
//   .field-details-wrapper {
//     max-height: 60em;
//   }
// }

// @media only screen and (max-width: 1200px) {
//   .main-block-new {
//     padding: 0 4em 0 4.5em;
//   }
//   .left-panel {
//     max-width: 20em;
//   }
//   .top-sec-new {
//     max-width: 20em;
//   }
//   .field-sec {
//     max-width: 20em;
//   }
//   .full-width-for-result{
//     max-width: 100%;
//   }
// }
