.main-block-pricing {
  width: 100%;
  float: left;
  margin: 0px;
  padding: 0px;
}

.for-yellowish-BG {
  position: absolute;
  bottom: 0;
  z-index: 0;
  width: 100%;
  float: left;
  /* height: 92.223vh; */
  background: radial-gradient(50% 100% at 50% 100%, #FFD803 0%, rgba(255, 216, 3, 0) 100%);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0px;
  padding: 0px;
  overflow-y: auto
}

.loader {
  border: .4vw solid rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  border-top: .4vw solid rgb(0, 0, 0);
  width: 2vw;
  height: 2vw;
  -webkit-animation: spin 1.1s linear infinite;
  /* Safari */
  animation: spin 1.1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.main-div {
  width: 100%;
  float: left;
  padding-top: 2.78%;
  padding-left: 12.362%;
  padding-right: 12.09%;
}

.main-header {
  font-weight: 700;
  font-size: 2.5vw;
  float: left;
}

.main-semiHeader {
  width: 100%;
  margin-top: 1.48%;
  float: left;
}

.seminHeader-content {
  width: 42%;
  float: left;
  font-weight: 400;
  font-size: 1.12vw;
}

.seminHeader-btn {
  width: 58%;
  float: right;
}

.btn-div-design {
  width: 25.8%;
  float: right;
  /* display: flex; */
  justify-content: center;
  padding: 2px;
  background: rgba(236, 236, 236, 1);
  border-radius: 4px;
}

.btn-div-design-select {
  width: 50%;
  background-color: white;
  border: none;
  border-radius: 4px;
  font-size: .99vw;
  padding: 7.55%;
  float: left;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  transition: .5s;
}

.btn-div-design-deselect {
  cursor: pointer;
  width: 50%;
  border: none;
  font-weight: 600;
  border-radius: 4px;
  font-size: .99vw;
  padding: 7.55%;
  float: left;
  background: rgba(236, 236, 236, 1);
  color: #A2A0A0;
  text-align: center;
  transition: .5s;
}

.btn-div-design-deselect:hover {
  color: #7b7b7b;
}

.plans-div {
  width: 100%;
  padding-top: 5%;
  display: flex;
  flex-wrap: wrap;
  float: left;
}

.main-plan-div {
  width: 23.9%;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 1.8%;
  padding-right: 1.8%;
  border: 2px solid rgba(31, 31, 31, 1);
  border-radius: 20px;
  box-shadow: 0px 4px 0px 0px rgba(31, 31, 31, 1);
  float: left;
  margin-right: 1.4%;
  position: relative;
  margin-bottom: 2.8%;
}

.main-plan-div:nth-last-child(1) {
  margin-right: 0%;
}

.recommend {
  float: left;
  background-color: rgba(255, 216, 3, 1);
  border: 1px solid black;
  font-size: .8vw;
  border-radius: 4px;
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 4%;
  padding-right: 4%;
  position: absolute;
  top: -3.1%;
}

.plan-name {
  width: 100%;
  float: left;
  font-weight: 700;
  font-size: 1.12vw;
  text-transform: uppercase;
  letter-spacing: 0.24em;
}

.plan-specification {
  width: 100%;
  float: left;
  font-weight: 400;
  font-size: .99vw;
  padding-top: 2%;
  padding-right: 4%;
}

.plan-payment {
  width: 100%;
  float: left;
  padding-top: 9.4%;
  display: flex;
}

.plan-amount {
  float: left;
  font-weight: 700;
  font-size: 3.06vw;
  line-height: 3.06vw;
  display: flex;
}

.plan-dollar {
  font-size: 1.6vw;
  line-height: 2.5vw;
}

.plan-amt-detail {
  float: left;
  font-size: .7vw;
  font-weight: 800;
  padding-left: 6.5%;
  padding-top: 2%;
}

.plan-upperPT {
  width: 100%;
  float: left;
  font-size: .7vw;
  font-weight: 800;
  text-decoration-line: underline;
}

.plan-lowerPT {
  float: left;
  font-size: .7vw;
  font-weight: 400;
}

.get-plan-button {
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  background-color: black;
  border: 2px solid black;
  float: left;
  color: white;
  padding-top: 4.7%;
  padding-bottom: 4.7%;
  text-align: center;
  font-weight: 700;
  font-size: 1.12vw;
  margin-top: 9.25%;
  transition: .2s;
}

.get-plan-button:hover {
  color: black;
  background-color: white;
  border: 2px solid black;
}

.get-plan-button-click {
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
  background-color: black;
  float: left;
  padding-top: 2.9%;
  padding-bottom: 2.9%;
  text-align: center;
  font-weight: 700;
  font-size: 1.12vw;
  margin-top: 9.25%;
  transition: .2s;
  color: black;
  background-color: white;
  border: 2px solid black;
}

.get-plan-button-sub {
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  background-color: white;
  border: 2px solid black;
  float: left;
  color: #2A2216;
  ;
  padding-top: 4.7%;
  padding-bottom: 4.7%;
  text-align: center;
  font-weight: 700;
  font-size: 1.12vw;
  margin-top: 9.25%;
  transition: .4s;
  box-shadow: 0px 4px 0px 0px #1F1F1F;
}

.get-plan-button-sub:hover {
  /* color: white; */
  background-color: #1F1F1F;
  border: 2px solid black;
  box-shadow: 0px 4px 0px 0px white;
}

.get-plan-button-sub-click {
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  background-color: white;
  border: 2px solid black;
  float: left;
  color: #2A2216;
  ;
  padding-top: 2.9%;
  padding-bottom: 2.9%;
  text-align: center;
  font-weight: 700;
  font-size: 1.12vw;
  margin-top: 9.25%;
  transition: .4s;
  box-shadow: 0px 4px 0px 0px #1F1F1F;
  display: flex;
  justify-content: center;
}

.get-plan-button-sub-not {
  width: 100%;
  border-radius: 8px;
  background-color: #FFD803;
  border: 2px solid black;
  float: left;
  color: #2A2216;
  cursor: pointer;
  padding-top: 3.86%;
  padding-bottom: 3.86%;
  text-align: center;
  font-weight: 700;
  font-size: 1.12vw;
  margin-top: 9.25%;
  transition: .4s;
  box-shadow: 0px 4px 0px 0px #1F1F1F;
  display: flex;
  justify-content: end;
}

.get-plan-button-sub-not-click {
  width: 100%;
  border-radius: 8px;
  background-color: #FFD803;
  border: 2px solid black;
  float: left;
  color: #2A2216;
  cursor: pointer;
  padding-top: 2.9%;
  padding-bottom: 2.9%;
  text-align: center;
  font-weight: 700;
  font-size: 1.12vw;
  margin-top: 9.25%;
  transition: .4s;
  box-shadow: 0px 4px 0px 0px #1F1F1F;
  display: flex;
  justify-content: center;
}

.currentPlanBtn {
  float: left
}

.currentPlan {
  width: 83%;
  float: left;
  padding-top: .7%;
}

.settingIcon {
  float: left;
  width: 11%;
  margin-right: 2%;
}

.plan-detail {
  width: 100%;
  float: left;
  padding-top: 13%;
}

.detail-header {
  width: 100%;
  font-size: .99vw;
  font-weight: 700;
  float: left;
}

.detail-content {
  width: 100%;
  float: left;
  padding-top: 2%;
}

.detailed-content {
  width: 100%;
  float: left;
  font-size: .88vw;
  margin-top: .5%;
}

.detailed-content img {
  width: 2.26%;
  margin-bottom: 1%;
  margin-right: 2%;
}

.main-content {
  width: 100%;
  float: left;
}

.current-plan {
  position: relative;
  width: 100%;
  float: left;
  padding: 2%;
  border: 2px solid rgba(31, 31, 31, 1);
  border-radius: 20px;
  margin-bottom: 3%;
  box-shadow: 0px 4px 0px 0px rgba(31, 31, 31, 1);
  font-size: 1.12vw;
  background: linear-gradient(180deg, #E3F6F5 0%, #FFFFFF 100%);
}

.all-plan {
  width: 100%;
  float: left;
  /* display: inline-flex; */
  display: flex;
  flex-wrap: wrap;
}

.recommend-CP {
  float: left;
  background-color: rgba(255, 216, 3, 1);
  border: 1px solid black;
  font-size: .8vw;
  border-radius: 4px;
  padding-top: .5%;
  padding-bottom: .5%;
  padding-left: 2.2%;
  padding-right: 2.2%;
  position: absolute;
  top: -18.1%;
  font-weight: 600;
}

.active-green {
  color: #00b100;
  text-transform: capitalize;
}

.active-red {
  color: rgb(229, 0, 0);
  text-transform: capitalize;
}

.settingIcon-CP {
  position: absolute;
  top: 9%;
  right: 1%;
  background-color: rgba(255, 216, 3, 1);
  width: 2.2vw;
  height: 2.2vw;
  padding: .5%;
  border-radius: 6px;
  border: 1px solid black;
  cursor: pointer;
  z-index: 100;
}

.right5 {
  right: 5%;
  display: flex;
  justify-content: center;
}
.right6 {
  right: 1%;
  display: flex;
  justify-content: center;
}

.right55 {
  width: 88%;
  display: flex;
  justify-content: center;
  /* padding-left: 15%; */
}

.settingIcon-CP-img {
  width: 100%
}

.settingIcon-CP-img-click {
  animation: spinSetting 1.9s linear infinite;
}
.survey-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Ensure it's above other content */
}

.survey-modal {
  width: 90%;
  max-width: 400px; /* Adjusted for closer width to the image */
  background-color: white;
  border-radius: 12px; /* Slightly increased border radius */
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add some shadow for depth */
}

.survey-header {
  background-color: #f0f0f0; /* Adjusted color to match the image */
  padding: 20px;
  color: black;
  font-size: 18px;
  position: relative;
  font-weight: 500; /* Slightly bolder font */
}

.survey-close {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  color: #a9a9a9; /* Lighter color for the close button */
}

.survey-body {
  padding: 20px;
  font-family: Arial, sans-serif; /* Adjusted font for better readability */
}

.survey-option {
  display: block;
  margin-bottom: 15px; /* More space between options */
  font-size: 16px;
  cursor: pointer;
}

.survey-option input[type="radio"] {
  appearance: none; /* Hide the default radio button */
  background-color: #fff; /* Background color */
  margin-right: 10px;
  cursor: pointer;
  width: 20px; /* Custom size */
  height: 20px; /* Custom size */
  border-radius: 50%; /* Make it round */
  border: 2px solid #000; /* Black border */
  position: relative;
  top: 4px; /* Adjust to center vertically with the text */
}

.survey-option input[type="radio"]:checked::after {
  content: ""; /* Needed to create the inner circle */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px; /* Size of the inner circle */
  height: 10px;
  background-color: #000; /* Black inner circle */
  border-radius: 50%;
  display: block;
}

.survey-option label {
  cursor: pointer; /* Ensure the label changes cursor as well */
}

.survey-footer {
  padding: 20px; /* More padding for footer */
  text-align: center; /* Center align the submit button */
}

.survey-submit {
  padding: 12px 25px;
  background-color: #000000; /* Changed to black */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.survey-submit:hover {
  background-color: #333333; /* Dark grey for hover effect */
}



@keyframes spinSetting {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 520px) and (max-width: 960px) {

  .main-div {
    padding-left: 9.362%;
    padding-right: 9.09%;
    float: left;
    /* background: radial-gradient(50% 100% at 50% 100%, #FFD803 0%, rgba(255, 216, 3, 0) 100%); */
    /* background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat; */
  }
  .right5 {
    right: 6%;
  }
  .right6 {
    right: 1%;
    display: flex;
    justify-content: center;
  }
  .loader {
    border: .7vw solid rgba(0, 0, 0, 0.6);
    border-top: .7vw solid rgb(0, 0, 0);
    width: 3vw;
    height: 3vw;
  }

  .main-header {
    font-size: 3.2vw;
  }

  .main-semiHeader {
    margin-top: 1.8%;
  }

  .seminHeader-content {
    width: 60%;
    font-size: 2.1vw;
  }

  .seminHeader-btn {
    width: 40%;
    float: right;
  }

  .btn-div-design {
    width: 65.8%;
    padding: 3px;
  }

  .btn-div-design-select {
    font-size: 1.99vw;
  }

  .btn-div-design-deselect {
    font-size: 1.99vw;
  }

  .main-plan-div {
    width: 29.9%;
    margin-right: 2.8%;
    margin-bottom: 2.8%;
  }

  .recommend {
    font-size: 1.5vw;
  }

  .plan-name {
    font-size: 1.8vw;
    margin-top: 3%;
  }

  .plan-specification {
    font-size: 1.45vw;
    padding-top: 4%;
  }

  .plan-amount {
    font-size: 4vw;
    line-height: 4vw;
  }

  .plan-dollar {
    font-size: 2.1vw;
    line-height: 3.1vw;
  }

  .plan-upperPT {
    font-size: 1.3vw;
  }

  .plan-lowerPT {
    font-size: 1.3vw;
  }

  .get-plan-button {
    border-radius: 6px;
    font-size: 1.8vw;
  }

  .get-plan-button-sub-not {
    border-radius: 6px;
    font-size: 1.8vw;
  }

  .get-plan-button-sub {
    border-radius: 6px;
    font-size: 1.8vw;
  }

  .detail-header {
    font-size: 1.5vw;
  }

  .detailed-content {
    font-size: 1.3vw;
    margin-top: 2%;
  }

  .detailed-content img {
    width: 3.26%;
    margin-bottom: 1%;
    margin-right: 2%;
  }

  .current-plan {
    padding: 2.5%;
    border-radius: 12px;
    font-size: 2vw;
  }

  .recommend-CP {
    font-size: 1.5vw;
  }

  .settingIcon-CP {
    width: 3vw;
    height: 3vw;
  }

}

@media only screen and (max-width: 520px) {

  .main-block-pricing {
    width: 100%;
    float: left;
    margin: 0px;
    padding: 0px;
  }

  .loader {
    border: 1.2vw solid rgba(0, 0, 0, 0.6);
    border-top: 1.2vw solid rgb(0, 0, 0);
    width: 6vw;
    height: 6vw;
  }


  .main-header {
    font-size: 5vw;
  }

  .seminHeader-content {
    width: 100%;
    float: left;
    font-size: 3.12vw;
  }

  .seminHeader-btn {
    width: 100%;
    float: left;
    display: flex;
    justify-content: center;
    padding-top: 5%;
    z-index: 19000000;
  }

  .btn-div-design {
    width: 45.8%;
    border-radius: 6px;
    padding: 3px;
  }

  .btn-div-design-select {
    border-radius: 6px;
    font-size: 3.5vw;
  }

  .btn-div-design-deselect {
    border-radius: 6px;
    font-size: 3.5vw;
  }

  .plans-div {
    width: 100%;
    float: left;
    margin: auto;
    padding-left: 16%;
    padding-right: 16%;
    padding-top: 3.5%;
  }

  .main-plan-div {
    width: 100%;
    padding-top: 8%;
    padding-bottom: 5%;
    padding-left: 7%;
    padding-right: 7%;
    border-radius: 15px;
    margin-bottom: 7%;
  }

  .recommend {
    font-size: 3vw;
  }

  .plan-name {
    width: 100%;
    font-size: 4vw;
    letter-spacing: 0.15em;
  }

  .plan-specification {
    font-size: 2.7vw;
  }

  .plan-amount {
    font-size: 7vw;
    line-height: 7vw;
  }

  .plan-dollar {
    font-size: 3.5vw;
    line-height: 6vw;
  }

  .plan-upperPT {
    font-size: 2.5vw;
  }

  .plan-lowerPT {
    font-size: 2.5vw;
  }

  .get-plan-button {
    font-size: 3.8vw;
  }

  .get-plan-button-sub-not {
    font-size: 3.8vw;
  }

  .get-plan-button-sub {
    font-size: 3.8vw;
  }

  .detail-header {
    font-size: 3vw;
  }

  .detailed-content {
    font-size: 2.4vw;
    margin-top: 2.5%;
  }

  .detailed-content img {
    width: 2.86%;
  }

  .current-plan {
    padding: 4.5%;
    padding-top: 9%;
    border-radius: 12px;
    font-size: 4vw;
    margin-bottom: 12%;
    margin-top: 9%;
  }

  .recommend-CP {
    font-size: 3vw;
    top: -10%
  }

  .settingIcon-CP {
    width: 6vw;
    height: 6vw;
    padding: 1%;
    top: 5%;
    right: 2.5%;
  }

  .right5 {
    right: 16%;
    display: flex;
    justify-content: center;
  }

  .right6 {
    right: 2%;
    display: flex;
    justify-content: center;
  }

  .right55 {
    width: 68%;
    display: flex;
    justify-content: center;
    /* padding-left: 15%; */
  }
}
