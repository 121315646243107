.main-modal {
  width: 100%;
  float: left;
  display: inline-flex;
  justify-content: center;
  padding-top: 7.22%;
  padding-left: 2%;
  padding-right: 2%;
  float: left;
}

.img-modal {
  width: 16%;
}

.header-modal {
  font-weight: 700;
  color: rgba(42, 34, 22, 1);
  font-size: 2.78vw;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  text-align: center;
  padding-top: 4.5%;
  padding-bottom: 2.25%;
  float: left;
}

.content-modal-1 {
  padding-left: 2%;
  text-align: center;
  padding-right: 2%;
  font-size: 1.12vw;
  color: rgba(42, 34, 22, 1);
  width: 100%;
  float: left;
}

.content-modal-2 {
  padding-left: 2%;
  text-align: center;
  padding-right: 2%;
  font-size: 1.12vw;
  color: rgba(42, 34, 22, 1);
  width: 100%;
  float: left;
  font-weight: 600;
  padding-bottom: 7.22%;
}

.button-modal {
  width: 100%;
  float: left;
  display: inline-flex;
  justify-content: center;
  padding-top: 5.33%;
  padding-bottom: 7.22%;
}

.button-modal button {
  background: linear-gradient(0deg, #FFD803, #FFD803);
  border: none;
  box-shadow: 0px 4px 0px 0px rgba(31, 31, 31, 1);
  border: 1px solid black;
  font-weight: 700;
  font-size: 1.12vw;
  border-radius: 8px;
  padding-top: 1.9%;
  padding-bottom: 1.9%;
  padding-left: 4.58%;
  padding-right: 4.58%;
  cursor: pointer;
}

.Modal {
  width: 37%;
  position: absolute;
  top: 53.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 2px solid rgba(31, 31, 31, 1);
  border-radius: 20px;
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
}

@media only screen and (min-width: 520px) and (max-width: 960px) {

  .img-modal {
    width: 20%;
  }

  .header-modal {
    font-size: 3.7vw;
  }

  .content-modal-1 {
    font-size: 1.45vw;
  }

  .content-modal-2 {
    font-size: 1.45vw;
  }

  .button-modal button {
    font-size: 1.8vw;
  }

  .Modal {
    width: 45%;
  }

}

@media only screen and (max-width: 520px) {

  .img-modal {
    width: 20%;
  }

  .header-modal {
    font-size: 5.8vw;
  }

  .content-modal-1 {
    font-size: 2.45vw;
  }

  .content-modal-2 {
    font-size: 2.45vw;
  }

  .button-modal button {
    font-size: 2.8vw;
  }

  .Modal {
    width: 75%;
  }

}