.profile-modal {
    background-color: rgba(255, 255, 255, 0.8);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
} 

.profile-block {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    min-width: 370px;
    -webkit-box-shadow: 2px 6px 26px -4px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 6px 26px -4px rgba(0,0,0,0.75);
    box-shadow: 2px 6px 26px -4px rgba(0,0,0,0.75);
    border-radius: 5px;
    padding: 35px 60px;
    justify-content: center;
    position: relative;

    .title {
        margin: 10px 0;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        
    }

    .group, .form-input {
        margin: 15px 0;
    }

    .modal-close {
        position: absolute;
        top: 30px;
        right: 30px;
        font-size: 30px;
        cursor: pointer;
    }
}
