.error-comp {
    min-width: 100%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .err-msg {
        font-size: 36px;
        font-weight: 600;

        span {
            color: lightblue;
        }
    }
}