.bg-color {
    background-color: #f8f8f8;
    min-width: 100%;
    min-height: 90vh;
    height: 100%;
}

.head-sec-centre {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
    align-self: center;
    text-align: center;
    h1 {
        font-size: 36px;
        line-height: 43px;
        font-weight: 600;
        margin-bottom: 12px;
    }
}

.year-month {
    margin: 25px;
    border: 1px solid #286ef1;
    border-radius: 25px;
    width: 200px;
    display: flex;
    justify-content: center;
    overflow: hidden;
    
    span {
        width: 50%;
        text-align: center;
        font-size: 20px;
        padding: 10px;
        font-weight: 600;
        color: #286ef1;
        cursor: pointer;
    }

    .active-plan {
        color: white;
        background-color: #286ef1;
    }
}

.plans {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: flex-start;
    margin: 0 0 30px 0;
    background-color: white;
    align-self: center;
    border-radius: 10px;
    border: 1px solid #ededed;
    overflow: hidden;
    padding: 15px 0;
    min-width: 320px;

    .sites {
        width: 100%;
        padding: 10px;
        border-top: 1px solid #ededed;
        border-bottom: 1px solid #ededed;

        .no-of-sites {
            text-align: center;
            font-weight: 600;
            font-size: 14px;
        }

        .three-col {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            border: .5px solid #ccc;
            border-radius: 3px;
            padding: 3px;
            
            .styled-btn {
                width: 33.3%;
                text-align: center;
                padding: 3px 8px;
                font-size: 14px;
                font-weight: 600;
                border: none;
                color: #888;
                background-color: white;
                border-radius: 4px;
                cursor: pointer;
            }
            
            .selected-btn {
                background-color: #eaf1fe;
                color: #286ef1;
                outline: 0;
            }
        }
    }
        
    .feature-list {
        width: 100%;

        .feature {
            text-decoration: none;
            padding: 10px 7px;
            text-align: center;
            list-style: none;
        }

        .feature:nth-child(even) {
            background-color: rgba(0,0,0,.02);
        }
    }
}   