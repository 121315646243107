.custom-button {
  min-width: 10.74vw;
  width: 100%;
  height: 6.42vh;
  line-height: 6.69vh;
  padding: 0 2.27vw 0 2.27vw;
  font-size:1.17vw;
  background-color: gainsboro;
  color: grey;
  border: none;
  cursor: pointer;
  border-radius: 0.53vh;
  border: 1px solid gainsboro;

  &.black-white {
    background-color: #FFD803;
    color: #0e172c;
    border: 1px solid #FFD803;
    font-weight: bold;
  }

  &.mr {
    margin-right: 10px;
  }

  &.ml {
    margin-left: 10px;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
