.navMenuContainer {
  position: relative;
}

.menuBox {
  display: flex;
  width: 16.66vw;
  padding: 3.74vh 0px 17.67vh 0px;
  flex-direction: column;
  gap: 1.6vh;
  border-radius: 1.6vh;
  background: #FFF;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 1.07vh 1.04vw;
}

.menuItem.selected {
  display: flex;
  height: 5.35vh;
  background: #FFD803;
}

.menuLink {
  text-decoration: none;
  color: rgb(33, 33, 33);
  margin-left: 2.08vw;
  display: flex;
  align-items: center;
}

.customIcon {
  margin-right: 1.65vw;
}
.menuItem img{
  height: 3.21vh;
  width: 1.56vw;
}

.text-field {
  color: #272343;
  font-family: Poppins;
  font-size: 2.14vh;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.menuItem.selected .text-field {
  font-weight: 700;
}
.menuItem:last-child,
.menuItem:nth-last-child(4)
{
cursor: pointer;
padding-left: 3.125vw;
}

