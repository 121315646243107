.bottom-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #E3F6F5;
    color: white;
    padding: 2.14vh 1.04vw;
    height:  9.37vh;
    justify-content: center;
    z-index: 100;
  }
  
  .popup-content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .whole-text{
    align-items: center;
    display: flex;
  }
  .popup-subtext{
    color: #1F1F1F;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .popup-text{
    color: #1F1F1F;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin-left: 12.3vw;
  }
  .progress-bar-color {
    background-color: #FFD803;
  }
  .processIndicator{
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    background-color: #FFD803;

  }
  
  
  
  