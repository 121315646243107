* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
}

.error {
  color: red;
  font-size: 1.33vh;
  margin: 0;
  padding: 0;
}
.breadcrumbs-container {
  margin-left:48.82vw;
  display: flex;
  position:absolute;
  justify-content: center;
}

.main-container {
  margin-top: 5.89vh;
  margin-left: 6.58vw; 
  display: flex;
  height: 100%;
}

.nav-menu {
  width: 13.02vw;
  /* background-color: #f0f0f0; */
}

.content {
  padding-left: 5.20vw;
}

