.hero-block {
  padding: 0 30px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  .title {
    font-size: 36px;
    font-weight: 300;
    text-align: center;
  }

  .desc {
    font-size: 20px;
    margin: 10px 0;
    text-align: center;
  }
}

.app-list-block {
  padding: 0 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  flex-wrap: wrap;
}
