@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&family=Montserrat:wght@400;600&family=Outfit:wght@400;500&display=swap");
.font-one{
  font-size: 1vw;
}
.navbar-block {
  // height: 9.37vh;
  background-color: white;
  width: 100%;
  // padding: 3.34vh 3.25vw;
  padding: 1.3888888889em 2.4305555556em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 99.0694444444em;
  margin: auto;
  // font-size: 2.40vh;
  // border-bottom: 3px solid #0e172c;
}
.banner-dots{
  width: 9px;
}
.rfm-marquee-container{
  overflow-y: hidden !important;
}
.grey{
  background-color: #F6F6F6;
}
.verify-license-btn {
  font-size: 14px;
  font-weight: 600;
  background-color: white;
  border: 1px solid #0e172c;
  padding: 7px 10px;
  border-radius: 5px;
  letter-spacing: 0.5px;
  text-decoration: none;
  color: #0e172c;
}
.data-main {
 display: flex;
 flex-direction: column;
 padding: 1.1111111111em 3.2638888889em;
 justify-content: center;
 align-items: center;
 border-radius: 0.9027777778em;
 background: #272343;
 //margin-right: 7.71vw;
 //margin-top: 3.21vh;
 -webkit-border-radius: 0.9027777778em;
 -moz-border-radius: 0.9027777778em;
 -ms-border-radius: 0.9027777778em;
 -o-border-radius: 0.9027777778em;
}

.data-title {
  // margin-bottom: 0.8vh;
  // color: #1F1F1F;
  // font-feature-settings: 'clig' off, 'liga' off;
  // font-family: Poppins;
  // font-size: 1.6vh;
  // font-style: normal;
  // font-weight: 700;
  // line-height: 1.87vh;
  // letter-spacing: 0.187vw;
  // text-transform: uppercase;
  color: #FFF;
text-align: center;
font-family: "Inter" !important;
font-size: 0.8em;
font-style: normal;
font-weight: 700;
line-height: 1.2;
text-transform: uppercase;
}

.data {
  // margin-top: 0.80vh;
  // color: #1F1F1F;
  font-feature-settings: 'clig' off, 'liga' off;
  // font-family: Poppins;
  // font-size: 2.94vh;
  // font-style: normal;
  // line-height: 2.94vh;
  display: flex;
  align-items: center;
  color: #FFF;
text-align: center;
font-family: "Inter" !important;
font-size: 1.25em;
font-style: normal;
font-weight: 700;
line-height: 1.2;
text-transform: uppercase;
}
.black-friday-banner-wrapper{
  gap: 2.3333vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #2E2E30;
  position: relative;
}
.banner-bottom-wrapper{
  display: flex;
  flex-direction: row;
  gap: 0.62109vw;
  align-items: end;
}
// @media only screen and (max-width: 1230px) {
//   .banner-claim{
//     padding: 10px 20px !important;
//     font-size: 20px;
//   }
//   .banner-content-wrapper{
//     gap: 30px;
//   }
// }
.banner-claim{
  display: flex;
  // max-width: 398px;
  // width: 100%;
  padding: 1.355vh 3.90625vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1.1595vw;
  border: 1.781px solid #000;
  background: #FFAB69;
  color: #000;
  text-align: center;
  font-family: "Public Sans";
  font-size: 1.953125vw;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 45px */
  letter-spacing: -0.6px;
  cursor: pointer;
}
.banner-bottom-left{
  width: 7.53606vw;
}
.banner-bottom-images-wrapper{
  display: flex;
  flex-direction: row;
  align-items: end;
}
.banner-content-wrapper{
  display: flex;
  flex-direction: row;
  gap: 3.2552vw;
  padding-bottom: 1.89701vh;
}
.banner-timer-wrapper{
  display: flex;
  flex-direction: row;
  gap: 1.953125vw;
}
.banner-time-box{
  width: 6.18489vw;
  min-height: 7.4525vh;
  padding: 0.677vh 1.85546875vw;
  color: #000;
  text-align: center;
  font-family: "Public Sans";
  font-size: 1.953125vw;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 45px */
  letter-spacing: -0.6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1.1595vw;
  border: 1.781px solid #000;
  background: #FFF;
}
.banner-right-bottom{
  width: 6.509895vw;
}
.banner-heading{
  width: 45.1258vw;
  max-width: 45.1258vw;
}
.banner-top-left{
  width: 6.53502vw;
  // height: 102.475px;
  // transform: rotate(-148.094deg);
  // position: absolute;
  // left: 198px;
  // top: -48.999px;
}
.black-friday-banner-wrapper{
  display: flex;
  flex-direction: column;
}
.banner-text-rotate-wrapper{
  background: #47474A;
  padding-top: 1.054vh;
  padding-bottom: 1.054vh;
  width: 100%;
}
.banner-top-right{
  margin-left: -1.9531vw;
  width: 9.79023vw;
}
.banner-rotate-wrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.banner-star{
  width: 33.392px;
  height: 33.392px;
  transform: rotate(-14.191deg);
}
.banner-subtext-text{
  color: #FFF4D9;
  font-family: "Public Sans";
  font-size: 1.513997vw;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 34.882px */
  letter-spacing: -0.465px;
}
.banner-subtext{
  color: #FFF4D9;
  font-family: "Public Sans";
  font-size: 1.5139973vw;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 34.882px */
  letter-spacing: -0.465px;
}
.banner-subtext-bold{
  font-weight: 700;
}

.banner-subtext-wrapper{
  display: inline-flex;
  flex-direction: row;
  gap: 0.65104vw;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.banner-subtext-wrapper-text{
  display: inline-flex;
  flex-direction: row;
  gap: 0.65104vw;
  padding-left: 0.65104vw;
  padding-right: 0.65104vw;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.rfm-marquee{
  margin-right: 0.65104vw !important;
  // margin-left: 1rem !important;
  min-width: initial !important;
}
.top-wrapper{
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: flex-start;
}
.top-images-wrapper{
  display: flex;
  gap: 3.78385vw;
  flex-direction: row;
  align-items: start;
}
.image-data {
  font-weight: 700;
}

.total-image {
  font-weight: 400;
}


.logo {
  color: black;
  text-decoration: none;

  .logo-text {
    // margin-top: 4.01vh;
    margin-left: 3.81vw;
    color: #333;
    font-family: "Poppins", sans-serif;
    font-size: 0.8vw;
    line-height: 1.3;
    font-weight: 900;
  }

  .pixie-text {
    font-size: 24px;
    font-family: 'Alegreya Sans SC', sans-serif;
  }

  .large-font {
    font-size: 24px;
  }

}

.nav-elements {
  display: flex;
  align-items: center;
  margin-top: 8px;

  .links {
    color: black;
    text-decoration: none;
    margin: 0 10px;
    letter-spacing: 1px;
    cursor: pointer;
  }

  .links:hover {
    text-decoration: underline;
    color: #face48;
  }
}

.navbar-link {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
}

.navbar-link-content {
  background-color: #FF9A03;
  float: left;
  width: 95%;
  padding: 15px 1% 14px 1%;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: #1d1d1d;
}

.navbar-link-content a {
  font-weight: 600;
  color: #1d1d1d;
}

.navbar-link-close {
  background-color: #FF9A03;
  float: left;
  padding: 15px 0px 14px 0px;
  font-size: 24px;
  width: 5%;
  top: 20%;
  right: 2%;
  text-align: end;
  padding-right: 2%;
  // font-family: noto sans;
  font-weight: 600;
  color: black;
  cursor: pointer;
}

.pixie-logo {
  width: 100%;
  max-width: 12.1527777778em;
flex-shrink: 0;
}
.nav-image {
  margin-right: 3.9vw;
  // width: 6vw;
  // height: 6vh;
}

.nav-text {
  padding-left: 2.67vh;
  width: 19.98vw;
  font-family: 'Poppins', sans-serif;
  color: rgba(0, 0, 0, 0.6);
  font-style: normal;
  font-weight: 400;
  font-size: 2.14vh;
  line-height: 3.21vh;
}
.main-modalForLinkmodal {
  width: 100%;
  float: left;
  // display: inline-flex;
  // justify-content: center;

}

.main__head {
  width: 100%;
  float: left;
}

.main__head__content {
  width: 88.5%;
  float: left;
  font-size: 24px;
  color: black;
  font-weight: 700;
  padding-top: 3.1%;
}

.main__content {
  width: 78.5%;
  float: left;
  font-size: 24px;
  color: black;
  font-weight: 400;
  text-align: left;
}

.main__content:after {
  content: "";
  display: inline-block;
  width: 100%;
}

.main__head__cancel {
  width: 11.5%;
  float: left;
  text-align: right;
}

.main__head__cancel img {
  width: 77.5%;
}

.ModalForLink {
  width: 72.7% !important;
  position: absolute;
  top: 20%;
  left: 14%;
  background-color: white;
  max-width: 90%;
  max-height: 90%;
  border-radius: 12px;
  box-shadow: 0px 0px 0px 36px #E3F6F5;
  padding-left: 7.55%;
  padding-bottom: 3.8%;
}

.main__content a {
  color: black;
}

@media only screen and (min-width: 520px) and (max-width: 960px) {

  .ModalForLink {
    width: 85% !important;
    top: 15%;
    left: 7.55%;
    padding-left: 5%;
    box-shadow: 0px 0px 0px 32px #E3F6F5;
  }

  .main__head__content {
    font-size: 21px;
  }
  
  .main__content {
    font-size: 21px;
    width: 88.5%;
  }

}

@media only screen and (max-width: 520px) {

  .ModalForLink {
    width: 80% !important;
    top: 15%;
    left: 10%;
    padding-left: 5%;
    box-shadow: 0px 0px 0px 28px #E3F6F5;
  }

  .main__head__content {
    font-size: 18px;
  }
  
  .main__content {
    font-size: 18px;
    width: 93.5%;
  }

  .main__head__cancel img {
    width: 97.5%;
  }
}