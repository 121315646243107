.main-block {
  position: relative;
}

.closeBtn {
  position: absolute;
  right: 80px;
  top: 20px;
  cursor: pointer;
}

.add-api-block {
  display: flex;
  height: 48px;
  min-width: 50vw;
  margin: 20px 0;

  input {
    width: 600px;
    padding: 3px 5px;
    border: 2px solid #0e172c;
    font-size: 18px;
  }

  button {
    padding: 3px 5px;
    font-size: 18px;
    background-color: #0e172c;
    height: 100%;
    border: none;
    color: #fffffe;
    cursor: pointer;
    width: 120px;

    &:disabled {
      cursor: not-allowed;
      color: whitesmoke;
    }
  }
}

.more-info-block {
  margin-top: 50px;
  display: flex;
  justify-items: start;
  flex-direction: column;
}

.bold {
  font-size: 18px;
  line-height: 130%;
  font-weight: 300;
}

.img-mw-800 {
  width: 100%;
  max-width: 930px;
}

.help-image-wrapper {
  margin-top: 20px;
}