.modal-container {
  width: 100%;
  max-width: 74.0972222222em;
  padding: 9.9305555556em 6.875em;
  border-radius: 25px;
  background: #fff;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 1em;
  left: auto;
  right: 1em;
  cursor: pointer;
}
.bg-grey {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
.modal-wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.modal-title {
  color: #000;
  text-align: center;
  max-width: 15.9em;
  text-align: center;
  font-size: 2.77em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 1.5em;
}
.modal-btn-wrapper {
  display: flex;
  width: 100%;
  max-width: 49.8611111111em;
  justify-content: center;
  align-items: center;
  gap: 2.0833333333em;
}
.modal-link-block {
  display: flex;
  max-width: 28.88em;
  padding: 1.3888888889em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.3888888889em;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 1.3888888889em;
  text-decoration: none;
  background: #ffe46d;
  border: 0.1388888889em solid transparent;
  -webkit-border-radius: 1.3888888889em;
  -moz-border-radius: 1.3888888889em;
  -ms-border-radius: 1.3888888889em;
  -o-border-radius: 1.3888888889em;
}

.modal-link-block:hover {
  border: 0.1388888889em solid #000;
}
.modal-btn-image {
  width: 3.4722222222em;
  height: 3.4722222222em;
}

.modal-btn-title {
  color: #000;
  text-align: center;
  font-size: 2.2222222222em;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
}

.modal-btn-sub-title {
  color: #000;
  font-size: 1.3888888889em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}
