.flex-center {
  width: 100%;
  min-height: 90vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
  .sign-up {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 40%;
    max-width: none;
    min-height: 30vh;
    max-height: none;
    background-color: white;
    -webkit-box-shadow: 2px 6px 26px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 6px 26px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 6px 26px -4px rgba(0, 0, 0, 0.75);
    border: 3px solid #0e172c;
    border-radius: 10px;
    padding: 40px 60px;

    .title {
      margin: 1.338vh 0;
      font-size:  1.6927vw;
      font-weight: 300;
      text-align: center;
    }

    .signup-form {
      max-width: 100%;
      align-self: stretch;
      align-items: center;
    }

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .tb-margin-40-20 {
      margin-top: 40px;
      margin-bottom: 20;
    }

    .non-user {
      margin: 4.016vh 0;
    }

    .link {
      cursor: pointer;
      text-decoration: none;
    }
  }
  .signup-container {
    gap: 16.861979vw;
    display: flex;
    align-items: center;
    width: 80%;
    min-height: 30vh;
    background-color: white;
    border-radius: 1.338vh;
  }
  
  .signup-form {
    align-items: center;
    flex: 1;
    max-width: 31.25vw;
    padding:  2.677vh 1.30vw;
  }
  .signup-button{
    margin-top: 2.94vh;
    width: 100%;
    background-color: gainsboro;
    color: grey;
  }
  .title {
    margin: 1.338vh 0;
    font-size: 2.34375vw;
    font-weight: bold;
  }
  
  .box-input-signup {
    border: 1px solid lightgray;
    border-radius: 0.66vh;
    padding: 1.338vh 0.651vw;
    width: 100%;
  }
  .tb-margin-24{
    margin-bottom: 3.212vh;
  }
  
  .error {
    color: red;
  }
  .error-box{
    border-color: red;
    outline: none;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .non-user {
    margin-top: 1.338vh;
  }
  
  .link {
    cursor: pointer;
    text-decoration: none;
  }
  
  .signup-image {
    width: 16.276vw;
    height: 33.467vh;
    min-width: 16.276vw;
    min-height: 33.467vh;
    margin-bottom: 6.693vh;
  }
  
  .image {
    width: 200%;
    height: 200%;
  
  }
  
  // .overlay-container {
  //   position: relative;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 2;
  // }
  
  
  // .overlay-image {
  //   position: absolute;
  //   top: -290px;
  //   right: -50px;
  //   margin-bottom: 200px;
  //   height: 290px;
  //   width: 290px;
  
  // }
  .base-image-sigup {
    margin-bottom: 2.677vh;
    z-index: 1;
    width: 150%;
    height: auto;
  }
  
  
  .google-or-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2.677vh;
  }
  
  .or-text {
    color: #000;
    opacity: 0.4;
    font-family: Poppins;
    font-size: 1.04166vw;
    font-style: normal;
    font-weight: 400;
    line-height: 3.212vh;
    text-align: center;
  }
  
  
  .google-button {
    display: flex;
    border-radius: 1.07vh;
    background: #345FF9;
    width: 28.645vw;
    height: 6.42vh;
    justify-content: center;
    align-items: center;
    border: 0px;
    gap: 0.651vw;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 1.041vw;
    font-style: normal;
    font-weight: 400;
    line-height: 3.212vh;
    cursor: pointer;
    margin-top: 2.677vh;
  }
  
  
  @media (max-width: 768px) { // For tablets and smaller devices
    .flex-center {
      min-height: 100vh;
      padding: 20px;
    }
  
    .signup-container {
      flex-direction: column;
      gap: 20px;
      width: 100%;
      min-height: auto;
      padding: 20px;
      border-radius: 10px;
    }
  
    .signup-form {
      padding: 20px;
      max-width: 100%;
    }
  
    .signup-image {
      width: 80%;
      height: auto;
      margin: 0 auto 20px;
    }
  
    .google-button {
      width: 100%;
      height: 50px;
      font-size: 14px;
    }
  
    .or-text {
      font-size: 14px;
    }
  
    .box-input-signup {
      padding: 12px;
      font-size: 14px;
    }
  
    .title {
      font-size: 18px;
    }
  
    .custom-button {
      font-size: 14px;
      padding: 12px 16px;
    }
  }
  
  @media (max-width: 480px) { // For smartphones
    .signup-container {
      padding: 10px;
      width: 100%;
      box-shadow: none;
      border: none;
    }
    .title-login{
      font-size: 3.34375vw;
    }
    .google-button-container{
      width: 100%;
    }
    .custom-button{
      line-height: normal;
    }
    .form-input-label{
      font-size: 3vw !important;
    }
    .signin-image{
      display: none;
    }
    .link{
      font-size: 2vw;
    }
    .non-user{
      font-size: 2vw;
    }
    .nav-elements{
      gap: 2vw;
    }
    .pixie-logo{
      max-width: 25em;
    }
    .nav-image{
      margin-right: 0;
    }
    .nav-text{
      font-size: 1vh;
      line-height: normal;
      padding-left: 0;
    }
    .signin-form{
      width: 100%;
      max-width: none;
    }
    .title {
      font-size: 16px;
    }
  
    .google-button {
      font-size: 12px;
      height: 40px;
    }
  
    .or-text {
      font-size: 12px;
    }
  
    .box-input-signup {
      padding: 10px;
      font-size: 12px;
    }
  
    .signup-image {
      width: 70%;
      height: auto;
    }
  
    .custom-button {
      font-size: 12px;
      padding: 10px 14px;
    }
  }
  