.top-bottom-block {
  display: flex;
  align-items: flex-start !important;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 80vh;
  text-align: left;
}

.alerts{
  margin-top: 0.67vh;
  margin-bottom: 8vh;
}
.button-container {
  display: flex;
  justify-content: space-between;
  gap: 13.02vw;
  white-space: nowrap;
  
}
.site-nam{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #272343;
  font-family: Poppins;
  font-size: 3.74vh;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.info-text{
  color: #272343;
  font-family: Poppins;
  font-size: 3.74vh;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.back-button{
  display: inline-flex;
  width: 48px;
  height: 48px;
  align-items: flex-start;
  gap: 10px;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  background: #272343;
  display: flex;
  justify-content: center;
  align-items: center;
}
.head-lite{
  color: #272343;
  font-family: Poppins;
  font-size: 2.4vh;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom:  0.66vh;
}
.head-sec {
  border-radius: 1.60vh;
  background: #FFF;
  padding-left: 0.78vw;
  padding-right: 3.71vw;
  padding-top: 4.81vh;
  width: 61.19vw;
}

.top-sec {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  text-align: left !important;
}
.top-head{
  display: flex;
  align-items: center;
  gap: 1.30vw;
}
.lower-sec{
  display: flex;
  align-items: center;
  margin-left: 4.42vw;
  margin-top: 3.61vh;
}

.info-text {
  font-size: 2.4vh;
  line-height: 2.94vh;
  text-align: left;
  margin-bottom: 4.81vh;
}
.collection-field{
  width: 61.19vw;
  // margin-top: 24px;
  border-radius: 1.6vh;
  background: #FFF;
  padding-left: 1.30vw;
  padding-right: 1.30vw;
  padding-top: 3.74vh;
  margin-right: 1.30vw;
}
.main-block{
  margin: 0;
  padding: 0;
}
.back-button img{
  height: 24px;
  width: 24px;
}
.collections-scroll-container{
  margin-top: 3.21vh;
  flex: 1;
  max-height: calc(100vh - 26.77vh);
  overflow-y: auto;
}
.collections-scroll-container::-webkit-scrollbar {
  width: 0.65vw;
}

.collections-scroll-container::-webkit-scrollbar-thumb {
  background-color: #1A1A1A;
  border-radius: 2.67vh;
}

.collections-scroll-container::-webkit-scrollbar-track {
  border-radius: 2.67vh;
  background-color: #ECECEC;
}
