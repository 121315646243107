#root {
  // background-color:#fffffe;
  height: 100vh;
}

#modal-div {
  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
} 


.inner-box {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  max-width: 39.06vw;
  -webkit-box-shadow: 2px 6px 26px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 2px 6px 26px -4px rgba(0,0,0,0.75);
  box-shadow: 2px 6px 26px -4px rgba(0,0,0,0.75);
  border-radius: 0.669vh;
  padding: 9.37vh 3.906vw;
  justify-content: center;
  position: relative;

  .title {
        margin: 1.338vh 0;
      font-size: 1.822vw;
      font-weight: 400;
      text-align: center;
      
  }

  .desc {
    font-size: 1.171vw;
    margin-bottom: 4.0160vh;
  }

  .or {
    margin: 0 14.97vw;
    font-weight: bold;
  }

  .modal-close {
      position: absolute;
      top: 4.01vh;
      right: 1.953vw;
      font-size: 1.953vw;
      cursor: pointer;
  }
}


.flex-column {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // min-height: 80vh;
  //background-color: #f3f3f3
  color: black; 
}
.add-site-box{
  display: flex;
  width: 20.7vw;
  height: 40vh;
  padding: 1.606vh 4.166vw;
  justify-content: center;
  align-items: center;
  gap: 0.651vw;
  flex-shrink: 0;
  border-radius: 2.141vh;
  border: 11px solid #E3F6F5;
  background: #FFF;
  color: #272343;
  text-align: center;
  font-family: Poppins;
  font-size: 1.692vw;
  font-style: normal;
  font-weight: 600;
  line-height: 4.524vh;
  cursor: pointer;
}
.main-block {
  background-color:#F6F6F6;
  width: 100%;
  height: 100%;
  // padding: 25px;
 // background-color: #f3f3f3;
  .content-box{
    margin-top: 4.016vh;
  }
  .heading-lite {
    color: #272343;
    font-family: Poppins;
    font-size: 3.51vw;
    font-style: normal;
    font-weight: 600;
    line-height: 7.228vh;
    letter-spacing: -1.08px;

    .username {
      text-transform: capitalize;
    }
  }
  .sub-text{
    margin-top: 1.606vh;
    margin-bottom: 5.354vh;
    color: #272343;
    font-family: Poppins;
    font-size: 1.302vw;
    font-style: normal;
    font-weight: 400;
    line-height: 2.67vh;
  }
  .outer-white-box {
    background-color: #E3F6F5;
    border-radius: 1.6064vh;
    padding: 2.677vh 1.302vw;
    position: relative;
}
.image-container {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 2.604vw;
  margin-bottom: 5.336vh;
}
.inner-white-box {
    min-width: 50vw;
    height: 52.342vh;
    border-radius: 1.606vh;
    background-color: white;
    padding: 2.67vh 1.302vw;
    flex: 1;
    position: relative;
}
  .margin-top-10{
    margin-top: 2.67vh;
  }
  .flex-wrap-block {
    width: 100%;
    // height: 100%;
    display: flex;
    // justify-content: space-evenly;
    // align-items: center;
    flex-wrap: wrap;
    gap: 2.67vh;

  }
  .site-box-scroll-container{
    flex: 1;
    max-height: calc(100vh - 26.77vh);
    overflow-y: auto;
  }
  .site-box-scroll-container::-webkit-scrollbar {
    width: 0.651vw;
  }
  
  .site-box-scroll-container::-webkit-scrollbar-thumb {
    background-color: #1A1A1A;
    border-radius: 2.677vh;
  }
  
  .site-box-scroll-container::-webkit-scrollbar-track {
    border-radius: 2.677vh;
    background-color: #ECECEC;
  }
  .add-site-btn {
    margin-top: 2.677vh;
    align-self: start;
    border: 3px solid #0e172c;
    border-radius: 1.338vh;
    background-color: #fec7d7;
    width: 13.02vw;
    height: 26.77vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    cursor: pointer;
    text-decoration: none;
    -webkit-box-shadow: 2px 4px 6px -4px rgba(0, 0, 0, 0.50);
    -moz-box-shadow: 2px 4px 6px -4px rgba(0, 0, 0, 0.50);
    box-shadow: 2px 4px 6px -4px rgba(0, 0, 0, 0.50);
    transition: box-shadow 0.3s ease-in-out;
    
    

    .plus {
      height: 4.01vh;
    }
    
    .click-text {
      color: #0d0d0d;
      margin-top: 2.67vh;
      font-weight: 500;
    }
  }

  .add-site-btn:hover {
    -webkit-box-shadow: 2px 8px 6px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 8px 6px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 8px 6px -4px rgba(0, 0, 0, 0.75);
    background-color:white;
  }
}
