body{
    background-color: #F6F6F6;
  }
  .collection-name{
    color: #272343;
    font-family: Poppins;
    font-size: 1.82vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 4.016vh;
  }
  .breadcrumbs{
    color: rgba(39, 35, 67, 0.70);
    font-family: Poppins;
    font-size: 0.97vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom:  1.472vh;
  }
  .white-box{
    width: 52.6vw;
    height: auto;
    flex-shrink: 0;
    border-radius: 1.6vh;
    background: #FFF;
    // margin-top: 50px;
    padding: 4.01vh 1.95vw;
  }
  .light-grey {
    border-radius: 0.53vh;
    background: #F6F6F6;
    // gap: 5.2vw;
    align-items: flex-start;
    display: flex;
    width: auto;
    padding: 0.8vh 0.39vw;
    flex-wrap: wrap;
    margin-right: 1.43vw;
  }
  .gap-5vw{
    gap: 5.2vw;
  }
  .gap-3vw{
    gap: 3.2vw;
  }
  .header-box {
    color: rgba(39, 35, 67, 0.80);
    font-family: Poppins;
    font-size: 1.04vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    // margin-left: 0.32vw;
  }
  .data-box-details {
    margin-left: 1.04vw;
    margin-top: 4.01vh;
    margin-bottom: 4.01vh; 
    // gap: 7.11vw;
    align-items: center; 
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    
  }
  .gap-7vw{
    gap: 7.11vw;
  }
  .gap-6vw{
    gap: 5.6vw;
  }
  .text-box{
    width: 0.065vw;
  }
  .data-group {
    display: flex;
    align-items: center;
    max-width: 100%;
    height: auto; 
  }
  .data-text-details {
    color: #272343;
    font-family: Poppins;
    font-size: 1.106vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 4vw;
    // flex: 0 0 auto;
  }
  
  // .small-image {
  //   width: 6.51vw;
  //   height: 14.72vh;
  //   flex: 0 0 6.51vw;
  //   margin-left: 1.95vw;
  // }
  // .small-image-skip {
  //   width: 6.51vw;
  //   height: 14.72vh;
  //   flex: 0 0 6.51vw;
  //   margin-left: 4.55vw;
  // }
  
  .divider-line {
    width: 100%;
    height: 0.1338vh;
    flex-shrink: 0;
    border-radius: 0.2677vh;
    background: rgba(0, 0, 0, 0.34);
  }
  
  .collection-box{
    margin-bottom: 8.032vh;
  }
  .add-left-padding{
    padding-left: 3.255vw;

  }
  .add-left-padding-saved{
    padding-left: 4.557vw;

  }
  
  .add-left-padding-main{
    padding-left: 1.302vw;

  }

  .add-left-padding-skip{
    padding-left: 4.231vw;
  }
  .add-right-padding-skip{
    padding-right:  1.1718vw;
  }
  .light-grey-skip{
    border-radius: 0.535vh;
    background: #F6F6F6;
    // gap: 2.929vw;
    align-items: flex-start;
    display: flex;
    width: auto;
    padding: 0.803vh 0.39vw;
    flex-wrap: wrap;
  }
  .data-box-skip{
    margin-left: 1.041vw;
    margin-top: 4.016vh;
    margin-bottom: 4.016vh;
    display: flex;
    // gap: 3.645vw;
    align-items: center;
    flex-wrap: wrap;
    color: #272343;
    font-family: Poppins;
    font-size: 1.106vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .adding-margin{
    margin-left: 3.5vw;
  }
  .adding-margin-skip{
    margin-left: 1.6vw;
  }
  .heading-field{
    display: flex;
    align-items: center;
    color: #272343;
    font-family: Poppins;
    font-size: 2.213vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 2.945vh;
    gap: 1.302vw;
  }
  .image-name {
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    overflow: hidden; /* Hide any overflowing text */
    text-overflow: ellipsis; /* Add an ellipsis (...) at the end of the text */
    // max-width: 150px; /* Adjust the maximum width as needed */
  }
  .item-name {
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    overflow: hidden; /* Hide any overflowing text */
    text-overflow: ellipsis; /* Add an ellipsis (...) at the end of the text */
    width: 12vw; /* Adjust the maximum width as needed */
  }
