.plan {
    min-width: 280px;
    height: 100%;
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;


    .plan-title {
        font-size: 24px;
        color: #333;
        font-weight: 600;
        margin-bottom: 17px;
        text-align: center;
    }

    .desc {
        color: #888;
        font-size: 14px;
        min-height: 30px;
        margin-bottom: 20px;
    }

    .plan-price {
        font-size: 32px;
        color: #333;
        margin-bottom: 10px;
        text-align: center;

        .price {
            font-size: 16px;
            line-height: 44px;
            color: #404040;
        }
    }

    .save-price {
        font-size: 14px;
        color: #aaa;
        font-style: italic;
        letter-spacing: -.23px;
        text-align: center;
        margin-bottom: 22px;

        .save {
            color: #8d8d8d;
            font-weight: 500;
        }

        .center-dash {
            text-decoration: line-through;
        }
    }

    .custom-link, .custom-link:hover {
        text-decoration: none;
        color: white;
    }
}