$sub-color: #d8d8d8;
$main-color: #212121;

@mixin shrinkLabel {
  top: -1.409vh;
  font-size: 0.781vw;
  color: $main-color;
}

.group {
  position: relative;
  // margin: 20px 0 5px;

  .form-input {
    background: none;
    background-color: white;
    color: $main-color;
    font-size: 1.171vw;
    padding: 1.338vh 0.65vw 1.338vh 0.32vw;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $sub-color;
    margin: 3.34vh 0 0;

    &:focus {
      outline: none;
      border-bottom: 1px solid $main-color;
    }

    &:focus ~ .form-input-label {
      @include shrinkLabel();
    }
  }

  input[type='password'] {
    letter-spacing: 0.3em;
  }

  .form-input-label {
    color: $sub-color;
    font-size: 1.041vw;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0.585vw;
    top: 45%;
    transform: translateY(-45%); 
    transition: 300ms ease all;

    &.shrink {
      @include shrinkLabel();
    }
  }
}