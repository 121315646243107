.search-bar{
    width: 46.6vw;
    height: 7.49vh;
    flex-shrink: 0;
    border-radius: 4.68vh;
    background: #FFF;
    padding: 0 2.27vw;
    border: none;
    color: #272343;
    font-feature-settings: 'salt' on;
    font-family: Poppins;
    font-size: 2.14vh;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-image: url('../../images/search 1.svg');
    background-repeat: no-repeat;
    text-indent: 1.3vw;
    background-position: 1.3vw center; 
    background-size: 3vh 3vw;
}
.search-bar:focus {
    outline: none;
}