.collections-data-top {
    margin: 2vh 0;
    h3 {
        font-size: 3.48vh;
        font-weight: 400;
        margin-bottom: 1.33vh;
    }
    .collection-header {
        display: flex;
        align-items: center;
        gap: 16.27vw;
      }
    .multi {
        border-radius: 5.35vh;
        background: #F6F6F6;
        padding: 1.07vh 0.91vw 1.07vh 1.041vw;
        justify-content: center;

    }
    .collection-name-row{
        width: 39.06vw;
    }
    .no-collection{
        margin-left: 1.75vw;
        margin-top: 4.41vh;
        margin-bottom: 4.68vh;
        color: #272343;
        font-family: Poppins;
        font-size: 2.40vh;
        font-style: normal;
        font-weight: 400;
        line-height: 2.40vh;
    }
    .no-collection a{
        color: #272343;
      }
    .border{
        margin-top: 5.35vh;
        background-color: #272343;
        height: 1px;
        width: 100%;
    }
    .border-items-fields{
        margin-top: 1vh;
        margin-bottom: 1vh;
        background-color: #272343;
        height: 1px;
        width: 100%;
    }
    .expand-button {
        max-width: 2.27vw;
        margin-bottom: 2.67vh;
        border: none;
        background-color: #FFF;
        cursor: pointer;
      }
    .selector-class select {
        -webkit-appearance: none;
        appearance: none;
        background-image: url('../../images/chevron-up 1.png');
        margin-left: 0.97vw;
        width: 8.46vw;
        border-radius: 2.67vh;
        background-color: #E7E7E7;
        color: #272343;
        background-size: 20px 20px;
        outline: none;
        background-repeat: no-repeat;
        background-position: right 0.65vw center;
      }
      .selector-class select:hover {
        background: #FFF;
        background-image: url('../../images/chevron-up 1.png');
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: right 0.65vw center;
      }   
      .selector-class select option {
        background-color: #fff;
        color: #272343;
      }


      
    .collection-name-txt{
        color: #272343;
        font-family: Poppins;
        font-size: 2.4vh;
        font-style: normal;
        font-weight: 400;
        // line-height: 2.4vh;
    }
    .field-name.txt{
        color: #272343;
        font-family: Poppins;
        font-size: 1.6vh;
        font-style: normal;
        font-weight: 400;
    }
    .checkbox-label{
        color: #272343;
        font-family: Poppins;
        font-size: 1.6vh;
        font-style: normal;
        font-weight: 400;
        line-height: 1.6vh;
    }
    .check-box-btn{
        height: 3.21vh;
        width: 1.56vw;
        padding: 0;
        margin: 0;
    }

    .single-collection {
        display: flex;
        align-items: center;
        width: calc(50% - 2.604vw);
        margin-right: 0.651vw;
        margin-bottom: 1.338vh;

        .disable-icon {
            cursor: pointer;
        }
    }
    .auto-optimized-field {
        pointer-events: none;
        color: #898989;
    }
    .row{
        display: flex;
        flex-wrap: wrap;
    }
}
.custom-checkbox {
    border-color: black;
    height: 3.21vh;
    width: 1.56vw;
  }
  
.each-field{
    align-items: center;
    display: flex;
}  
  
select, option {
    margin-right: 0.65vw;
    background-color: #0e172c;
    color: white;
    padding: 1.07vh 0.65vw;
    border: none;
    border-radius: 0.66vh;
    font-size: 1.6vh;
}
.fields{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2.67vh;
}
.expand-arrow{
    height: 3.21vh;
    width: 1.56vw;
}
.circular-loader{
    display: flex;
    width: 100%;
    justify-content: center;
}