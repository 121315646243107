.flex-center {
  width: 100%;
  min-height: 90vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
  .signin-container {
    gap: 16.27vw;
    display: flex;
    align-items: center;
    width: 80%;
    min-height: 30vh;
    background-color: white;
    border-radius: 1.33vh;
  }
  .signin-form {
    align-items: center;
    flex: 1;
    max-width: 31.25vw;
    padding: 2.67vh 1.3vw;
  }
  .signin-button{
    margin-top: 2.945vh;
    width: 100%;
    background-color: gainsboro;
    color: grey;
  }
  .title-login {
    margin: 1.338vh 0;
    font-size: 2.34375vw;
    font-weight: bold;
  }
  .box-input-signin {
    border: 1px solid lightgray;
    border-radius: 0.669vh;
    padding: 1.3386vh 0.65vw;
    width: 100%;
  }
  .tb-margin-24{
    margin-bottom: 2.67vh;
  }
  
  .error {
    color: red;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .non-user {
    color: #2D334A;
    text-align: right;
    font-family: Poppins;
    font-size: 0.78vw;
    font-style: normal;
    font-weight: 400;
    line-height: 1.606h;
  }
  
  .link {
    cursor: pointer;
    color: #2D334A;
    font-family: Poppins;
    font-size:  0.78vw;
    font-style: normal;
    font-weight: 400;
    line-height: 1.606vh;
    position: relative;
    text-decoration-line: underline;
  }
  
  .link:after {
    content: "";
    display: block;
    width: 100%;
    height: 0.133vh;
    background-color: black;
    position: absolute;
    bottom: -0.267vh;
    left: 0;
  }
  .sign-in {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 40%;
    max-width: none;
    min-height: auto;
    max-height: none;
    background-color: white;
    -webkit-box-shadow: 2px 6px 26px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 6px 26px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 6px 26px -4px rgba(0, 0, 0, 0.75);
    border: 2px solid #f3f3f3;
    border-radius: 10px;
    padding: 40px 60px;
    border: 3px solid #0e172c;

    .login-form {
      width: auto;
      align-self: stretch;
    }
    

    .title {
      margin: 10px 0;
      font-size: 26px;
      letter-spacing: 1px;
      font-weight: 300;
      text-align: center;
    }

    .center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .tb-margin-20 {
      margin: 20px 0 10px 0;
    }

    .tb-margin-40-20 {
      margin-top: 40px;
      margin-bottom: 20px;
    }
    .mb-20{
      margin-bottom: 20px;
    }
    .link {
      cursor: pointer;
      text-decoration: none;

    }
  }



.custom-button-webflow {
  margin-top: 2.008vh;
  min-width: max-content;
  width: auto;
  height: auto;
  letter-spacing: 0.0651vw;
  line-height: 6.693vh;
  padding: 0 2.278vw 0 2.278vw;
  font-size: 1.1718vw;
  background-color: #0e172c;
  color: #fffffe;
  font-weight: bolder;
  border: none;
  cursor: pointer;
  border-radius: 0.535vh;
  border: 1px solid #0e172c;
  display: flex;
  justify-content: center;
  align-items: center;

  & img{
    margin-left: 5px;
  }

  &.black-white {
    background-color: white;
    color: #0e172c;
    border: 1px solid #0e172c;

  }

  &.mr {
    margin-right: 10px;
  }

  &.ml {
    margin-left: 10px;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
.signin-image {
  width: 16.276vw;
  height: 33.467vh;
  min-width: 16.276vw;
  min-height: 33.467vh;
  margin-bottom: 6.693vh;
}

.error-text {
  color: red;
}
.error-box{
  border-color: red;
  outline: none;
}

.base-image {
  margin-bottom: 2.677vh;
  width: 150%;
}

.google-or-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.677vh;
}
.or-text {
  color: #000;
  opacity: 0.4;
  font-family: Poppins;
  font-size: 1.0416vw;
  font-style: normal;
  font-weight: 400;
  line-height: 3.21vh;
  text-align: center;
}

.google-button {
  display: flex;
  border-radius: 1.07vh;
  background: #345FF9;
  width: 28.64vw;
  height: 6.42vh;
  justify-content: center;
  align-items: center;
  border: 0px;
  gap: 0.651vw;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: Poppins;
  font-size: 1.041vw;
  font-style: normal;
  font-weight: 400;
  line-height: 3.212vh;
  cursor: pointer;
  margin-top: 2.677vh;
}

.links-container {
  display: flex;
  justify-content: space-between;
}