.header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.33vh;
  }
  .heading-field{
    display: flex;
    align-items: center;
    color: #272343;
    font-family: Poppins;
    font-size: 2.213vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 2.945vh;
    gap: 1.302vw;
  }
  .white-box{
    width: 52.60vw;
    height: auto;
    flex-shrink: 0;
    border-radius: 1.6vh;
    background: #FFF;
    margin-top: 1.67vh;
    padding: 4.01vh 1.95vw;
    margin-bottom: 6vh;
  }
  .light-grey-box {
    border-radius: 0.53vh;
    background: #F6F6F6;
    gap: 5.2vw;
    align-items: flex-start;
    display: flex;
    width: auto;
    padding: 0.8vh 0.39vw;
    flex-wrap: wrap;
    margin-right: 1.3vw;
  }
  .collection-name{
    color: #272343;
    font-family: Poppins;
    font-size: 1.82vw;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1.01vh;
  
  }
  .header-box {
    color: rgba(39, 35, 67, 0.80);
    font-family: Poppins;
    font-size: 1.041vw;
    font-style: normal;
    font-weight: 500;
    line-height: normal; 
    margin-left: 0.32vw;
  }
  .meta-item {
    display: flex;
    align-items: center;
    gap: 9.11vw;
  }
  .data-text{
    text-align: center;
    width: 1.17vw;
  }
  .divider-line {
    width: 46.875vw;
    height: 0.13vh;
    flex-shrink: 0;
    border-radius: 0.26vh;
    background: rgba(0, 0, 0, 0.34);
  }
  .long-divider-line {
    width: 46.875vw;
    height: 0.401vh;
    flex-shrink: 0;
    border-radius: 0.267vh;
    background: rgba(0, 0, 0, 0.34);
  }
  .detail-class{
    color: #1229F2;
    font-family: Poppins;
    font-size:  1.106vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
  .add-padding-left{
    padding-left: 1.43vw;
  }
  .add-margin-right{
    margin-right: 1.17vw;
    margin-left: 0.26vw;
  }
  .data-box{
    margin-left: 1.04vw;
    margin-top: 4.01vh;
    margin-bottom: 4.01vh;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: #272343;
    font-family: Poppins;
    font-size: 1.106vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .gap-btw-array{
    gap: 5vh;
  }